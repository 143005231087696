import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import ImageGallery from "@Molecule/ImageGalleryLandingPage";
import VoucherPrakerjaLandingPage from "@Molecule/VoucherPrakerjaLandingPage";
import HeaderLandingPage from "@Molecule/HeaderLandingPage";
import CounterLandingPage from "@Molecule/CounterLandingPage";
import PhoneMockup from "@Molecule/PhoneMockupLandingPage";
import CarouselLandingPageClass from "@Molecule/CarouselLandingPageCategory";
import WheelLandingPage from "@Molecule/WheelLandingPage";
import Testimonials from "@Molecule/Testimonials";
import FaqLandingPage from "@Molecule/FAQLandingPage";
import FooterLandingPage from "@Molecule/FooterLandingPage";
import ContactLandingPage from "@Molecule/ContactMeLandingPage";
import AccordionLandingPage from "@Molecule/AccordionLandingPage";
import FindClassLandingPage from "@Molecule/FindClassLandingPage";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
export default function LandingPageLayout({ data }) {
  const navigate = useNavigate();

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  }, []);

  return (
    <div className={Styles.background}>
      <div className={Styles.viewScrollBehindTopBar} id="beranda" />
      <div className={Styles.doodleWrapper}>
        <img className={Styles.bg} src={Images.DOODLE_BG} alt="background" />
        <section className={Styles.gradientBackground}>
          <main className={`${Styles.mainContent} ${Styles.withMaxWidth}`}>
            <img
              src={Images.LEFT_CLAY}
              className={Styles.leftClay}
              alt="clay"
            />
            <img
              src={Images.RIGHT_CLAY}
              className={Styles.rightClay}
              alt="clay"
            />
            <img
              src={Images.LEFT_CLAY}
              className={Styles.leftClayBottom}
              alt="clay"
            />
            <HeaderLandingPage />
            <PhoneMockup />
            <VoucherPrakerjaLandingPage />
            <CounterLandingPage />
            {/* <BannerLandingPage /> */}
            <ImageGallery />
          </main>
        </section>
        <CarouselLandingPageClass />
        <div className={Styles.titleSearchClass}>
          <h1>
            Cari <span>{" Kelasmu "}</span> Sekarang
          </h1>
        </div>
        <FindClassLandingPage
          data={data?.data?.prakerjaClass}
          onClick={() => navigate("/courses")}
        />
        <FindClassLandingPage
          type={"regular"}
          data={data?.data?.regulerClass}
          onClick={() => navigate("/courses")}
        />
      </div>
      <div className={Styles.noDoodle}>
        <WheelLandingPage />
        <Testimonials />
        <FaqLandingPage />
        <AccordionLandingPage />
        <ContactLandingPage />
        <FooterLandingPage />
      </div>
    </div>
  );
}
